import { BN } from '@coral-xyz/anchor'
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import Decimal from 'decimal.js'
import { Collateral } from '../utils/OracleAccounts'

export default class VaultTypeForClient {
  name: string
  collateral: Collateral
  collateralIcon: string
  loanInitFee: BN
  minCollateralRatio: BN
  liqCollateralRatioBuffer: BN
  interestPerSecond: BN
  minDebt: BN
  maxDebt: BN
  emergencyModeThreshold: BN
  redeemable: boolean
  collateralMint: PublicKey
  quickDescription: string
  title: string
  displayInUI: boolean
  lamportsPerCollateralUnit: Decimal
  whiteListOnly = false
  enableLeverage: boolean
  maxLeverage: number
  featuredInUI = false
  vaultCategory: string
  bannerDescription: string

  constructor(config: {
    name: string
    collateral: Collateral
    collateralIcon: string
    loanInitFee: BN
    minCollateralRatio: BN
    liqCollateralRatioBuffer?: BN
    interestPerSecond: BN
    minDebt: BN
    maxDebt: BN
    emergencyModeThreshold: BN
    redeemable: boolean
    collateralMint: PublicKey
    quickDescription: string
    title: string
    displayInUI: boolean
    lamportsPerCollateralUnit: Decimal
    whiteListOnly?: boolean
    enableLeverage: boolean
    maxLeverage: number
    featuredInUI?: boolean
    vaultCategory?: string
    bannerDescription?: string
  }) {
    this.name = config.name
    this.collateral = config.collateral
    this.collateralIcon = config.collateralIcon
    this.loanInitFee = config.loanInitFee
    this.minCollateralRatio = config.minCollateralRatio 
    this.liqCollateralRatioBuffer = config.liqCollateralRatioBuffer || new BN(0)
    this.interestPerSecond = config.interestPerSecond
    this.minDebt = config.minDebt
    this.maxDebt = config.maxDebt
    this.emergencyModeThreshold = config.emergencyModeThreshold
    this.redeemable = config.redeemable
    this.collateralMint = config.collateralMint
    this.quickDescription = config.quickDescription
    this.title = config.title
    this.displayInUI = config.displayInUI
    this.lamportsPerCollateralUnit = config.lamportsPerCollateralUnit
    this.whiteListOnly = !!config.whiteListOnly
    this.enableLeverage = config.enableLeverage
    this.maxLeverage = config.maxLeverage
    this.featuredInUI = !!config.featuredInUI
    this.vaultCategory = config.vaultCategory || config.collateral.toString()
    this.bannerDescription = config.bannerDescription || ""
  }

  static interestPerSecondToYear(rate: BN|string|Decimal): string {
    return new Decimal(rate.toString())
      .div(new Decimal(10).pow(18))
      .add(1)
      .pow(new Decimal(60 * 60 * 24 * 365))
      .sub(1)
      .mul(100)
      .toDP(2)
      .toString()
  }

  static getInterestRatePerSecond(rate: number): string {
    // See calculation here: https://www.wolframalpha.com/input?i=%281.06%29%5E%281%2F%28365*24*60*60%29%29
    // const interestPerSecond = new BN('0000000001847694957') // 6%

    const rateString = new Decimal(1 + rate)
      .pow(new Decimal(1).div(365 * 24 * 60 * 60))
      .sub(1)
      .mul(new Decimal(10).pow(18))
      .floor()
      .toString()
    return rateString
  }

  displayLoanInitFee(): string {
    return new Decimal(this.loanInitFee.toNumber())
      .div(LAMPORTS_PER_SOL)
      .mul(100)
      .toString()
  }

  displayInterestRate(): string {
    return VaultTypeForClient.interestPerSecondToYear(this.interestPerSecond)
  }

  displayMinCollateralRatio(): string {
    return new Decimal(this.minCollateralRatio.toNumber())
      .div(LAMPORTS_PER_SOL)
      .mul(100)
      .toString()
  }
}
