import { Connection, PublicKey } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token'

import {Vault, IDL } from 'hedge-web3'

import Decimal from 'decimal.js'
import { BN, Program, AnchorProvider } from '@coral-xyz/anchor'
import { Wallet } from '@coral-xyz/anchor/dist/cjs/provider'

export const HEDGE_PROGRAM_ID = 'HedgeEohwU6RqokrvPU4Hb6XKPub8NuKbnPmY7FoMMtN'
export const HEDGE_PROGRAM_PUBLICKEY = new PublicKey(HEDGE_PROGRAM_ID)

export const WAD = new Decimal('1000000000000000000')

export function HedgeProgram(connection: Connection, wallet: Wallet) {
  // const con = new Connection(connection.rpcEndpoint)
  const provider = new AnchorProvider(connection, wallet, {
    skipPreflight: false,
    commitment: 'confirmed',
    preflightCommitment: 'confirmed',
  })
  // console.log('HedgeProgram', provider)
  return new Program<Vault>(IDL, HEDGE_PROGRAM_PUBLICKEY, provider)
}

export const u128ToDecimal = (input: number | string | BN): Decimal => {
  return new Decimal(input.toString()).div(WAD)
}

const enc = new TextEncoder()

export const getLiquidationPoolStatePublicKey = async (): Promise<PublicKey> => {
  const [findMintPublicKey] = await PublicKey.findProgramAddressSync(
    [enc.encode('LiquidationPoolStateV1')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return findMintPublicKey
}
export const getPoolUshAccountPublicKey = async (): Promise<PublicKey> => {
  const [findMintPublicKey, bump] = await PublicKey.findProgramAddressSync(
    [enc.encode('LiquidationPoolUSHAccountV1')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return findMintPublicKey
}
export const getUshMintPublicKey = async (): Promise<PublicKey> => {
  const [findMintPublicKey, bump] = await PublicKey.findProgramAddressSync(
    [enc.encode('UshMintV1')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return findMintPublicKey
}
export const getVaultSystemStatePublicKey = async (): Promise<PublicKey> => {
  const [publicKey, bump] = await PublicKey.findProgramAddressSync(
    [enc.encode('VaultSystemStateV1')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return publicKey
}
export const getHedgeMintPublicKey = async (): Promise<PublicKey> => {
  const [publicKey, bump] = await PublicKey.findProgramAddressSync(
    [enc.encode('HEDGEMintV1')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return publicKey
}
export const getMintPublicKeyWithPDA = async (pdaString: string): Promise<PublicKey> => {
  const [publicKey, bump] = await PublicKey.findProgramAddressSync(
    [enc.encode(pdaString)],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return publicKey
}
export const getPoolPublicKeyForMint = async (mintPublicKey) => {
  const strToEncode = mintPublicKey.toString().substring(0, 12)
  const [publicKey, bump] = await PublicKey.findProgramAddressSync(
    [enc.encode(strToEncode)],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return publicKey
}

export async function getVaultTypeAccountPublicKey(collateralType: string): Promise<PublicKey> {
  const [vaultTypeAccount] = await PublicKey.findProgramAddressSync(
    [enc.encode(collateralType), enc.encode('State')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return vaultTypeAccount
}
export async function getVaultTypeOracleAccountPublicKey(
  collateralType: string
): Promise<PublicKey> {
  const [vaultTypeOracleAccount] = await PublicKey.findProgramAddressSync(
    [enc.encode(collateralType), enc.encode('Oracle')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return vaultTypeOracleAccount
}
export async function getReferralStatePublicKey(): Promise<PublicKey> {
  const [referralStateAccountPublicKey] = await PublicKey.findProgramAddressSync(
    [enc.encode('ReferralStateV1')],
    new PublicKey(HEDGE_PROGRAM_ID)
  )
  return referralStateAccountPublicKey
}

export const findAssociatedTokenAddress = async (
  walletAddress: PublicKey,
  tokenMintAddress: PublicKey
) => {
  return (
    await PublicKey.findProgramAddressSync(
      [walletAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), tokenMintAddress.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0]
}
