import { Transaction, VersionedTransaction } from '@solana/web3.js'

export default class TransactionStep {
  public status: StepStatus
  public error = ''
  public signature = ''
  constructor(
    public title: string,
    public buildTransaction: () => Promise<Transaction | VersionedTransaction>
  ) {
    this.status = StepStatus.Waiting
  }
}

export enum StepStatus {
  Waiting = 'Waiting',
  Building = 'Building',
  Signing = 'Signing',
  Submitting = 'Submitting',
  Processing = 'Processing',
  Confirming = 'Confirming',
  Success = 'Success',
  Fail = 'Fail',
  Skip = 'Skip',
}

export type TransactionStepImmutable = {
  status: StepStatus
  title: string
  error: string
  signature: string
}
