import { HermesClient } from '@pythnetwork/hermes-client'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { AccountInfo, PublicKey, Keypair, Connection } from '@solana/web3.js'
import { AggregatorAccount, loadSwitchboardProgram } from '@switchboard-xyz/switchboard-v2'
import mixpanel from 'mixpanel-browser'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { handleConnectionError } from '../lib/utils/handleConnectionError'
import { HedgeProgram } from '../on-chain/utils/HedgeConstants'
import { getPythProgramKeyForCluster } from '@pythnetwork/client'
import { 
  Collateral, 
  getOracleAccount, 
  OracleProvider,
  PYTH_PRICE_FEEDS
} from '../on-chain/utils/OracleAccounts'
import {
  recentPriceThunk,
  refreshHdgPrice,
  refreshUserVaults,
  refreshUshPrice,
  refreshVaultSystemState,
  refreshVaultTypes,
  refreshWallet,
  reloadVaultInList,
  userSolBalanceThunk
} from '../reducers/walletReducer'

const WalletBalanceProvider = ({ dispatch, networkConnection, children }) => {
  const { publicKey: userWalletPublicKey } = useWallet()
  const { connection } = useConnection()
  const Wallet = useWallet()
  const router = useRouter()

  useEffect(() => {
    dispatch(refreshWallet(connection, userWalletPublicKey))
  }, [userWalletPublicKey])

  useEffect(() => {
    if (userWalletPublicKey) {
      mixpanel.alias(userWalletPublicKey.toString())
      mixpanel.identify(userWalletPublicKey.toString())
      mixpanel.track('Wallet connected', {
        distinct_id: userWalletPublicKey.toString(),
      })
      mixpanel.people.set({ 'Recent Wallet': userWalletPublicKey.toString() })
    }
  }, [userWalletPublicKey])

  // Pyth price feed setup
  useEffect(() => {
    try {
      console.log('Set up price listeners on network', networkConnection.displayName())
      
      // Create Hermes connection
      const hermesClient = new HermesClient('https://hermes.pyth.network', {})
      console.log('Created Hermes connection')

      // Get price feed IDs for supported collateral types
      const priceFeedIds = Object.values(PYTH_PRICE_FEEDS)
      console.log('Price feed IDs:', priceFeedIds)

      // Set up streaming updates
      hermesClient.getPriceUpdatesStream(priceFeedIds, {
        parsed: true // Get parsed price updates
      }).then(eventSource => {
        eventSource.onmessage = (event) => {
          const priceUpdate = JSON.parse(event.data)
          // console.log('Received price update:', priceUpdate)

          // The price updates come as an array of parsed price feeds
          if (priceUpdate.parsed) {
            priceUpdate.parsed.forEach(feed => {
              // Normalize feed ID by removing '0x' prefix if present
              const normalizedFeedId = feed.id.replace('0x', '')
              
              // Find matching price feed ID in PYTH_PRICE_FEEDS
              const collateralEntry = Object.entries(PYTH_PRICE_FEEDS).find(
                ([_, id]) => id.replace('0x', '') === normalizedFeedId
              )
              
              if (collateralEntry && feed.price) {
                const [collateralStr] = collateralEntry
                const collateral = collateralStr as Collateral
                const price = feed.price.price * Math.pow(10, feed.price.expo)
                
                // console.log('Dispatching price update:', {
                //   collateral,
                //   price,
                //   feedId: feed.id,
                //   matchedCollateral: collateralStr
                // })
                
                dispatch(recentPriceThunk(price, collateral))
              } else {
                console.log('No matching collateral found for feed ID:', feed.id)
              }
            })
          }
        }

        eventSource.onerror = (error) => {
          console.error('Error in price feed:', error)
          handleConnectionError(error)
        }

        return () => {
          console.log('Closing Hermes connection')
          eventSource.close()
        }
      })

    } catch (error) {
      console.error('Error in price feed setup:', error)
      handleConnectionError(error)
    }
  }, [networkConnection])

  // Switchboard price feed setup
  useEffect(() => {
    const fetchSwitchboardPrices = async () => {
      try {
        console.log('Fetching Switchboard prices for network:', networkConnection.displayName())
        const program = await loadSwitchboardProgram(
          'mainnet-beta',
          connection,
          Keypair.generate()
        )
        console.log('Loaded switchboard program')

        // Fetch cUSDC price
        const aggregatorAccountCUSDC = new AggregatorAccount({
          program,
          publicKey: new PublicKey('7Y3nWv5B2rLiDBsNpkfXqa4cbJqszJos2sZVutF8R3FE'),
        })
        const cUSDCValue = await aggregatorAccountCUSDC.getLatestValue()
        console.log('Got cUSDC price from switchboard', cUSDCValue)
        dispatch(recentPriceThunk(cUSDCValue.toNumber(), Collateral.CUSDC))

        // Fetch cUSDT price
        const aggregatorAccountCUSDT = new AggregatorAccount({
          program,
          publicKey: new PublicKey('7xC7k76f2CQYRuzjyCdqmYM6kKHNpxBx89e7hw2xMf5Q'),
        })
        const cUSDTValue = await aggregatorAccountCUSDT.getLatestValue()
        console.log('Got cUSDT price from switchboard', cUSDTValue)
        dispatch(recentPriceThunk(cUSDTValue.toNumber(), Collateral.CUSDT))
      } catch (error) {
        console.error('Error fetching Switchboard prices:', error)
        handleConnectionError(error)
      }
    }

    fetchSwitchboardPrices()
  }, [networkConnection]) // Only re-fetch when network changes

  // Mixpanel route tracking
  useEffect(() => {
    const handleRouteChange = (url) => {
      mixpanel.track('Page view', {
        url: url,
      })
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  // Load vault types
  useEffect(() => {
    const program = HedgeProgram(connection, Wallet)
    dispatch(refreshVaultSystemState(program))
    dispatch(refreshVaultTypes(program))
  }, [networkConnection])

  // Load user vaults
  useEffect(() => {
    const program = HedgeProgram(connection, Wallet)
    dispatch(refreshUserVaults(program, userWalletPublicKey))
  }, [networkConnection, userWalletPublicKey])

  // Load HDG and USH prices
  useEffect(() => {
    dispatch(refreshHdgPrice())
    dispatch(refreshUshPrice())
  }, [networkConnection, userWalletPublicKey])

  // Monitor user's SOL balance
  useEffect(() => {
    let id: number
    if (userWalletPublicKey) {
      try {
        id = connection.onAccountChange(userWalletPublicKey, (accountInfo) => {
          dispatch(userSolBalanceThunk(accountInfo.lamports))
        })
        dispatch(refreshWallet(connection, userWalletPublicKey))
      } catch (error) {
        handleConnectionError(error)
      }
    }
    return () => {
      id && connection.removeAccountChangeListener(id)
    }
  }, [networkConnection, userWalletPublicKey])

  // Monitor vault events
  useEffect(() => {
    let ids = []
    const finalizeConnection = new Connection(networkConnection.networkUrl, 'finalized')
    const program = HedgeProgram(finalizeConnection, Wallet)
    
    const events = [
      'LiquidateVaultEvent',
      'NewVaultEvent',
      'DepositVaultEvent',
      'WithdrawVaultEvent',
      'LoanVaultEvent',
      'RepayVaultEvent',
      'RedeemVaultEvent'
    ] as const
    
    events.forEach(eventName => {
      ids.push(
        program.addEventListener(eventName, (event) => {
          console.log(`${eventName}:`, event)
          if (event.vault) {
            dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
          }
          dispatch(refreshVaultSystemState(program))
          dispatch(refreshVaultTypes(program))
        })
      )
    })

    return () => {
      ids.forEach(id => program.removeEventListener(id))
    }
  }, [networkConnection])

  return <>{children}</>
}

function mapStateToProps(state) {
  const { networkConnection } = state.wallet
  return {
    networkConnection,
  }
}

export default connect(mapStateToProps)(WalletBalanceProvider)
