import classNames from 'classnames'
import { List } from 'immutable'
import { FunctionComponent } from 'react'
import { Button, Card, Col, Container, Row, Toast, ToastContainer } from 'react-bootstrap'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Transaction } from '../lib/loading/Transaction'
import {
  setLoadingScreenMessage,
  setLoadingScreenShowing,
  setLoadingScreenTransactionSteps,
} from '../reducers/loadingScreenReducer'

import { CSSTransition } from 'react-transition-group'
import { RootState } from '../pages/_app'
import TransactionStep, { StepStatus, TransactionStepImmutable } from '../lib/TransactionStep'
import Link from 'next/link'
import { NetworkConnection } from '../on-chain/utils/NetworkConnections'
import _ from 'underscore'
import { Explorer } from '../on-chain/utils/Explorers'

export default function LoadingScreen() {
  const dispatch = useDispatch()
  const showing = useSelector((state: RootState) => state.loadingScreen.showing)
  const title = useSelector((state: RootState) => state.loadingScreen.title)
  const transactionSteps = useSelector(
    (state: RootState) => state.loadingScreen.transactionSteps
  ) as List<TransactionStepImmutable>

  const handleClose = async () => {
    dispatch(setLoadingScreenTransactionSteps([]))
    dispatch(setLoadingScreenShowing(false))
  }

  function getButton() {
    let allDone = true

    transactionSteps.forEach((step) => {
      if (
        !(
          step.status === StepStatus.Success ||
          step.status === StepStatus.Fail ||
          step.status === StepStatus.Skip ||
          step.status === StepStatus.Waiting
        )
      ) {
        allDone = false
      }
    })

    return (
      <Button onClick={handleClose} className="mt-3 px-5" disabled={!allDone}>
        Dismiss
      </Button>
    )
  }

  return (
    <div>
      <div>
        <CSSTransition in={showing} timeout={300} classNames="loading-transition">
          {showing ? (
            <div className="loading-screen">
              <Container>
                <Row>
                  <Col xl={{ span: 4, offset: 4 }} lg={{ span: 6, offset: 3 }}>
                    <Card>
                      <Card.Header className="text-center h5">{title}</Card.Header>
                      <Card.Body className="p-4">
                        <div>
                          {transactionSteps.map((step, index) => {
                            return <Step step={step} index={index} key={step.title} />
                          })}
                        </div>
                        <div className="text-center">{getButton()}</div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : (
            <></>
          )}
        </CSSTransition>
      </div>
    </div>
  )
}

function Step({ step, index }: { step: TransactionStepImmutable; index: number }) {
  const networkConnection = useSelector(
    (state: RootState) => state.wallet.networkConnection
  ) as NetworkConnection
  const explorer = useSelector(
    (state: RootState) => state.wallet.explorer
  ) as Explorer
  return (
    <div>
      <div className="d-flex mb-3">
        <div
          className="me-3 d-flex align-items-center"
          style={{
            borderRadius: 10,
            background: getStepColor(step.status),
            height: 50,
            minWidth: 50,
          }}
        >
          {getStepIcon(step.status)}
        </div>
        <div>
          <div className="fw-bold text-primary" style={{ fontSize: 10 }}>
            Transaction {index + 1}
          </div>
          <div>{step.title}</div>
          <div className="small text-muted">{getStepSubtext(step, networkConnection, explorer)}</div>
        </div>
      </div>
    </div>
  )
}

function getStepIcon(status) {
  switch (status) {
    case StepStatus.Waiting:
      return <i className="fa-thin fa-timer fa-1x mx-auto" />
    case StepStatus.Building:
      return <i className="fa-thin fa-hammer fa-1x mx-auto fa-beat-fade" />
    case StepStatus.Signing:
      return <i className="fa-thin fa-signature fa-1x mx-auto fa-beat-fade" />
    case StepStatus.Submitting:
      return <i className="fa-thin fa-spinner fa-1x mx-auto fa-spin" />
    case StepStatus.Processing:
      return <i className="fa-thin fa-spinner-third fa-1x mx-auto fa-spin" />
    case StepStatus.Confirming:
      return <i className="fa-thin fa-circle-notch fa-1x mx-auto fa-spin" />
    case StepStatus.Success:
      return <i className="fa-thin fa-check fa-1x mx-auto" />
    case StepStatus.Fail:
      return <i className="fa-thin fa-triangle-exclamation fa-1x mx-auto" />
    case StepStatus.Skip:
      return <i className="fa-thin fa-check fa-1x mx-auto" />
    default:
      return <i className="fa-thin fa-save fa-1x mx-auto" />
  }
}
function getStepColor(status): string {
  switch (status) {
    case StepStatus.Waiting:
      return 'rgba(255,255,255,0.2)'
    case StepStatus.Building:
      return '#9063CD'
    case StepStatus.Signing:
      return '#9063CD'
    case StepStatus.Submitting:
      return '#9063CD'
    case StepStatus.Processing:
      return '#9063CD'
    case StepStatus.Confirming:
      return '#9063CD'
    case StepStatus.Success:
      return '#D62598'
    case StepStatus.Fail:
      return 'red'
    case StepStatus.Skip:
      return '#D62598'
    default:
      return 'rgba(255,255,255,0.2)'
  }
}
function getStepSubtext(step: TransactionStepImmutable, networkConnection: NetworkConnection, explorer: Explorer) {
  if (step.error) {
    return step.error
  }
  if (step.signature && step.status === StepStatus.Success) {
    return (<>
      {'Success! You can view the transaction '}
      <Link
        href={`${explorer.baseUrl}/tx/${step.signature}?cluster=${networkConnection.cluster}`}
        target="_blank">
        here
      </Link>
    </>);
  }
  switch (step.status) {
    case StepStatus.Waiting:
      return 'Pending previous transactions'
    case StepStatus.Building:
      return 'Building the transaction'
    case StepStatus.Signing:
      return 'Please sign the transaction'
    case StepStatus.Submitting:
      return 'Submitting to the cluster'
    case StepStatus.Processing:
      return 'Waiting for the first confirmation'
    case StepStatus.Confirming:
      return 'Waiting for majority confirmation'
    case StepStatus.Success:
      return 'Transaction Success'
    case StepStatus.Fail:
      return 'Transaction Failed'
    case StepStatus.Skip:
      return 'Skipped'
    default:
      return 'rgba(255,255,255,0.2)'
  }
}

function ErrorIcon() {
  return (
    <div style={{ height: 70 }}>
      <i className="fa-thin fa-circle-exclamation me-2 fa-4x" />
    </div>
  )
}
function LoadingIcon() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
function SuccessIcon() {
  return (
    <svg
      id="successAnimation"
      className="animated"
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
    >
      <path
        id="successAnimationResult"
        fill="#D8D8D8"
        d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
      />
      <circle
        id="successAnimationCircle"
        cx="35"
        cy="35"
        r="24"
        stroke="#979797"
        strokeWidth="2"
        strokeLinecap="round"
        fill="transparent"
      />
      <polyline
        id="successAnimationCheck"
        stroke="#979797"
        strokeWidth="2"
        points="23 34 34 43 47 27"
        fill="transparent"
      />
    </svg>
  )
}
