import { PublicKey } from '@solana/web3.js'

interface BirdeyeResponse {
  data: {
    value: number
    updateUnixTime: number
    updateHumanTime: string
    priceChange24h: number
  }
  success: boolean
}

interface JupiterResponse {
  data: {
    [key: string]: {
      id: string
      type: string
      price: string
    }
  }
  timeTaken: number
}

export class PriceFetcher {
  private static BIRDEYE_API_KEY = 'b427f92378f349c2bdf8a967363b38c4'
  
  static async getTokenPrice(tokenMint: string): Promise<number> {
    try {
      // Try Jupiter first
      const jupiterPrice = await this.getJupiterPrice(tokenMint)
      if (jupiterPrice) {
        return jupiterPrice
      }
      
      // Fallback to Birdeye
      const birdeyePrice = await this.getBirdeyePrice(tokenMint)
      if (birdeyePrice) {
        return birdeyePrice
      }
      
      return 0
    } catch (error) {
      console.error('Error fetching price:', error)
      return 0
    }
  }

  private static async getJupiterPrice(tokenMint: string): Promise<number | null> {
    try {
      const response = await fetch(
        `https://api.jup.ag/price/v2?ids=${tokenMint}&showExtraInfo=true`
      )
      const data = await response.json() as JupiterResponse
      const price = data?.data?.[tokenMint]?.price
      return price ? parseFloat(price) : null
    } catch (error) {
      console.warn('Jupiter price fetch failed:', error)
      return null
    }
  }

  private static async getBirdeyePrice(tokenMint: string): Promise<number | null> {
    try {
      const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'x-chain': 'solana',
          'X-API-KEY': this.BIRDEYE_API_KEY
        }
      }
      
      const response = await fetch(
        `https://public-api.birdeye.so/defi/price?address=${tokenMint}`,
        options
      )
      const data = await response.json() as BirdeyeResponse
      return data.success ? data.data.value : null
    } catch (error) {
      console.warn('Birdeye price fetch failed:', error)
      return null
    }
  }
}