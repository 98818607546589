// CSS
import '@fortawesome/fontawesome-pro/css/all.css'
import 'rc-slider/assets/index.css'
import '../styles/custom-bootstrap.scss'
import '../styles/custom-nav-dropdown.scss'
import '../styles/custom-wallet.scss'
import '../styles/custom-link.scss'
import '../styles/custom.scss'
import '../styles/gradient-box.scss'
import '../styles/homepage-headers.scss'
import '../styles/loading-screen.scss'
import '../styles/sticky-footer.scss'
import '../styles/custom-wallet.scss'
import '../styles/custom-nav-dropdown.scss'
import '../styles/homepage-headers.scss'
import '../styles/gradient-box.scss'
import '@fortawesome/fontawesome-pro/css/all.css'
import 'rc-slider/assets/index.css'
import '../styles/notifi.scss';

// Package
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import mixpanel from 'mixpanel-browser'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MixpanelProvider } from 'react-mixpanel'
import { Provider as ReduxProvider } from 'react-redux'
import dynamic from 'next/dynamic'
import WalletBalanceProvider from '../providers/WalletBalanceProvider'
import * as ga from '../lib/ga'
import SiteBanner from '../components/SiteBanner'
import configureStore from '../configureStore'
import LoadingScreen from '../components/LoadingScreen'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const store = configureStore()

export type RootState = ReturnType<typeof store.getState>

mixpanel.init('bf14edef568859eedbb7e13c146027b6')

Sentry.init({
  dsn: 'https://bc996251977c40888f0e1dd20cde65e5@o1039440.ingest.sentry.io/6008378',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id })
  //   }
  //   return event
  // },
  enabled: process.env.NODE_ENV !== 'development',
})

// Dynamically import the WalletConnectionProvider with ssr disabled
const WalletConnectionProvider = dynamic(
  () => import('../providers/WalletConnectionProvider'),
  {
    ssr: false
  }
)

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  // const location = useLocation()
  // const history = useHistory()

  useEffect(() => {
    const { pathname } = router
    const params = new URLSearchParams(location.search)
    if (params.has('referral')) {
      const jsonStorage = params.get('referral')
      localStorage.setItem('referral', jsonStorage)
      console.log('Found referral number: ' + jsonStorage)
    }
    params.delete('referral')
    router.replace({ pathname, query: params.toString() }, undefined, { shallow: true })
  }, [])

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url)
    }
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <title>Hedge - 0% Interest Liquidity on Solana</title>
      </Head>

      <MixpanelProvider mixpanel={mixpanel}>
        <ReduxProvider store={store}>
          <WalletConnectionProvider>
            <WalletBalanceProvider>
              <div className="main-holder dark">
                <SiteBanner />
                <Component {...pageProps} />
              </div>
              <LoadingScreen />
              <ToastContainer
                position="bottom-right"
                autoClose={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </WalletBalanceProvider>
          </WalletConnectionProvider>
        </ReduxProvider>
      </MixpanelProvider>
    </>
  )
}

export default MyApp

export type AppDispatch = typeof store.dispatch
