import * as borsh from '@coral-xyz/borsh'
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import Decimal from 'decimal.js'
import { numberWithCommas } from '../../lib/Utils'
import { u128ToDecimal } from '../utils/HedgeConstants'
import ReferralAccount from './ReferralAccount'
import VaultType from './VaultType'
export default class VaultAccount {
  publicKey: PublicKey
  vaultOwner: PublicKey
  pdaSalt: string
  //** Collateral in Lamports */
  deposited: Decimal
  denormalizedDebt: Decimal
  debtProductSnapshotBytes = new Decimal(1)
  collateralAccumulatorSnapshotBytes = new Decimal(0)
  vaultType: VaultType
  vaultStatus: string
  timeCreated: number
  timeLastInteraction: number
  nextVaultToRedeem: PublicKey
  vaultTypePublicKey: PublicKey
  vaultNumber: number
  vaultTypeName: string

  constructor(config: any, publicKey: PublicKey, vaultTypeAccount: VaultType) {
    this.publicKey = publicKey
    this.vaultOwner = config.vaultOwner
    this.pdaSalt = config.pdaSalt
    this.deposited = new Decimal(config.deposited.toString())
    this.denormalizedDebt = new Decimal(config.denormalizedDebt.toString())
    if (config.debtProductSnapshotBytes) {
      this.debtProductSnapshotBytes = u128ToDecimal(config.debtProductSnapshotBytes.toString())
    }
    if (config.collateralAccumulatorSnapshotBytes) {
      this.collateralAccumulatorSnapshotBytes = u128ToDecimal(
        config.collateralAccumulatorSnapshotBytes.toString()
      )
    }
    this.vaultStatus = Object.keys(config.vaultStatus || {})[0]
    this.vaultTypePublicKey = config.vaultType
    this.timeCreated = config.timeCreated
    this.timeLastInteraction = config.timeLastInteraction

    this.nextVaultToRedeem = config.nextVaultToRedeem
    this.vaultNumber = config.vaultNumber
    this.vaultTypeName = config.vaultTypeName
    this.vaultType = vaultTypeAccount
  }

  isOwnedBy(userWalletPublicKey: PublicKey) {
    return userWalletPublicKey && this.vaultOwner.toString() === userWalletPublicKey.toString()
  }

  getCollateral(vaultDelta?: VaultDelta): Decimal {
    const collateralDelta =
      vaultDelta && vaultDelta.collateralValid ? new Decimal(vaultDelta.collateral) : new Decimal(0)

    const extraCollateral = new Decimal(this.denormalizedDebt).mul(
      this.vaultType.collateralRedistributionAccumulator.sub(
        this.collateralAccumulatorSnapshotBytes
      )
    )

    const collateral = new Decimal(this.deposited.toString())
      .add(extraCollateral)
      .div(this.vaultType.lamportsPerCollateralUnit)
      .add(collateralDelta)
    return Decimal.max(collateral, new Decimal(0))
  }

  displayCollateral(vaultDelta?: VaultDelta): string {
    return (
      numberWithCommas(this.getCollateral(vaultDelta).toNumber(), 2) +
      ` ${this.vaultType.collateral.toString()}`
    )
  }

  getCollateralValue(collateralPrice: number, vaultDelta?: VaultDelta): Decimal {
    return this.getCollateral(vaultDelta).mul(new Decimal(collateralPrice))
  }

  displayCollateralValue(collateralPrice: number, vaultDelta?: VaultDelta): string {
    return `${this.getCollateralValue(collateralPrice, vaultDelta)} USD`
  }

  getTotalDebt(vaultDelta?: VaultDelta): Decimal {
    // Apply any outstanding debt distributions
    const currentDenormalizedDebt = this.vaultType.debtRedistributionProduct
      .div(this.debtProductSnapshotBytes)
      .mul(new Decimal(this.denormalizedDebt))

    const currentCumulativeRate = this.vaultType.cumulativeRate

    // Compound the debt for current time delta
    const secondsSinceCompound = new Decimal(Date.now() / 1000).sub(
      this.vaultType.cumulativeRateLastUpdated
    )
    const updatedCumulativeRate = currentCumulativeRate.mul(
      secondsSinceCompound.mul(this.vaultType.interestRatePerSecond).add(1)
    )
    const normalizedDebt = currentDenormalizedDebt.div(LAMPORTS_PER_SOL).mul(updatedCumulativeRate)

    let debtDelta =
      vaultDelta && vaultDelta.debtValid ? new Decimal(vaultDelta.debt) : new Decimal(0)

    let totalDebt = Decimal.max(normalizedDebt.add(debtDelta), new Decimal(0))

    if (vaultDelta) {
      totalDebt = totalDebt.add(vaultDelta.getLoanFee())
      totalDebt = totalDebt.sub(vaultDelta.getReferralDiscount())
    }
    return totalDebt
  }

  displayTotalDebt(vaultDelta?: VaultDelta): string {
    const debt = this.getTotalDebt(vaultDelta)

    if (debt.isZero()) {
      return '-'
    }
    return `${numberWithCommas(debt.toNumber(), 6)} USH`
  }

  getMinDebt(): Decimal {
    return this.vaultType.minDebtPerVault.mul(LAMPORTS_PER_SOL)
  }

  displayMinDebt(): string {
    return `${this.getMinDebt().toFixed(2)} USH`
  }

  getCollateralRatio(collateralPrice: number, vaultDelta?: VaultDelta): Decimal {
    const collateral = this.getCollateral(vaultDelta)
    const debt = this.getTotalDebt(vaultDelta)

    // const collateral = Decimal.max(new Decimal(this.deposited)
    // .div(this.vaultType.lamportsPerCollateralUnit)
    // .add(collateralDelta), new Decimal(0))

    return collateral.mul(collateralPrice).div(debt)
  }

  displayCollateralRatio(collateralPrice: number, vaultDelta?: VaultDelta): string {
    if (collateralPrice === 0) {
      return '(loading)'
    }

    const debtDelta = vaultDelta && vaultDelta.debtValid ? vaultDelta.debt : new Decimal(0)

    if (this.denormalizedDebt.isZero() && debtDelta.isZero()) {
      return this.deposited.greaterThan(0) ? '∞' : '-'
    }
    const ratio = this.getCollateralRatio(collateralPrice, vaultDelta)
    if (ratio.isNaN()) {
      return '-'
    }
    if (!ratio.isFinite()) {
      return '∞'
    }
    return `${ratio.mul(100).toNumber().toFixed(2)} %`
  }

  getLiquidationPrice(vaultDelta?: VaultDelta): Decimal {
    if (this.getTotalDebt(vaultDelta).isZero() || this.getCollateral(vaultDelta).isZero()) {
      return new Decimal(0)
    }
    const liquidationPrice = this.vaultType.liquidationCollateralRatio
      .mul(this.getTotalDebt(vaultDelta))
      .div(this.getCollateral(vaultDelta))

    return liquidationPrice
  }

  displayLiquidationPrice(vaultDelta?: VaultDelta): string {
    if (this.getTotalDebt(vaultDelta).isZero()) {
      return 'Never'
    }
    if (vaultDelta?.debtValid && !vaultDelta.debt.isZero()) {
      return `$${numberWithCommas(this.getLiquidationPrice(vaultDelta), 2)} USD`
    }
    return `$${numberWithCommas(this.getLiquidationPrice(vaultDelta), 2)} USD`
  }

  getLiquidationDelta(collateralPrice: number): Decimal {
    if (this.getTotalDebt().isZero() || this.getCollateral().isZero()) {
      return new Decimal(Number.MAX_VALUE)
    }
    const delta = new Decimal(collateralPrice).sub(this.getLiquidationPrice())
    return delta
  }

  displayLiquidationDeltaFromPrice(collateralPrice: number): string {
    if (this.getTotalDebt().isZero() || this.getCollateral().isZero()) {
      return '-'
    }
    const delta = this.getLiquidationDelta(collateralPrice)
    return `${delta.toFixed(2)} ${delta.greaterThan(0) ? 'USD Away' : 'Under'}`
  }

  getCurrentLeverage(collateralPrice: number): Decimal {
    if (this.getTotalDebt().isZero()) {
      return new Decimal(1)
    }
    return this.getCollateralRatio(collateralPrice).div(
      this.getCollateralRatio(collateralPrice).sub(1)
    )
  }

  getCurrentLeverageBase(collateralPrice: number): Decimal {
    return this.getCollateral().div(this.getCurrentLeverage(collateralPrice))
  }

  getMinCollateralRatio(): Decimal {
    return this.vaultType.minCollateralRatio
  }
  
  displayMinCollateralRatio(): string {
    return `${this.getMinCollateralRatio().mul(100).toNumber().toFixed(0)}%`
  }
  
  getLiquidationCollateralRatio(): Decimal {
    return this.vaultType.liquidationCollateralRatio
  }

  displayLiquidationCollateralRatio(): string {
    return `${this.getLiquidationCollateralRatio().mul(100).toNumber().toFixed(0)}%`
  }

  displayInterestRatePerYear(): string {
    const rate = VaultAccount.interestPerSecondToYear(this.vaultType.interestRatePerSecond)
    return `${rate}%`
  }

  displayLoanInitFee(): string {
    return `${this.vaultType.loanInitFee.mul(100).toString()}%`
  }

  isUnderCollateralRatio(collateralPrice: number, vaultDelta?: VaultDelta): boolean {
    return this.getCollateralRatio(collateralPrice, vaultDelta)
      .sub(this.getMinCollateralRatio())
      .isNegative()
  }

  isUnderLiquidationCollateralRatio(collateralPrice: number, vaultDelta?: VaultDelta): boolean {
    return this.getCollateralRatio(collateralPrice, vaultDelta)
      .sub(this.getLiquidationCollateralRatio())
      .isNegative()
  }

  isUnderMinDebt(vaultDelta: VaultDelta): boolean {
    const debtDelta =
      vaultDelta && vaultDelta.debtValid ? new Decimal(vaultDelta.debt) : new Decimal(0)
    const newTotal = this.getTotalDebt().add(debtDelta)
    if (newTotal.lessThanOrEqualTo(0) || newTotal.greaterThanOrEqualTo(this.getMinDebt())) {
      return false
    }
    return true
  }

  getRecoveryPrice(vaultDelta?: VaultDelta): Decimal {
    if (this.getTotalDebt(vaultDelta).isZero() || this.getCollateral(vaultDelta).isZero()) {
      return new Decimal(0)
    }
    const recoveryPrice = this.vaultType.emergencyModeThreshold
      .mul(this.getTotalDebt(vaultDelta))
      .div(this.getCollateral(vaultDelta))

    return recoveryPrice
  }

  displayRecoveryPrice(vaultDelta?: VaultDelta): string {
    if (!this.vaultType.hasRecoveryMode() || this.getTotalDebt(vaultDelta).isZero()) {
      return ''
    }
    return `$${numberWithCommas(this.getRecoveryPrice(vaultDelta), 2)} USD`
  }

  static interestPerSecondToYear(rate: Decimal): string {
    return rate
      .add(1)
      .pow(new Decimal(60 * 60 * 24 * 365))
      .sub(1)
      .mul(100)
      .toDP(2)
      .toString()
  }

  static FromMiniSlice(data: Buffer, pubkey: PublicKey, vaultType: VaultType) {
    const props = [borsh.u64('deposited'), borsh.u64('denormalizedDebt')]
    const miniVaultLayout = borsh.struct(props, 'minVaultLayout')
    const decodedData: any = miniVaultLayout.decode(data)

    return new VaultAccount(decodedData, pubkey, vaultType)
  }
}

export class VaultDelta {
  // Whole collateral units 1 == 1SOL == 10^9 Lamports
  // Whole collateral units 1 == 1RAY == 10^6 Lamports
  public collateral: Decimal = new Decimal(0)
  public collateralValid: boolean = false

  // Whole debt units 1 == 1USH == 10^9 UshLamports
  public debt: Decimal = new Decimal(0)
  public debtValid: boolean = false
  public vaultType: VaultType = undefined
  public referralAccount: ReferralAccount = undefined
  constructor(
    isDepositing: boolean,
    depositInput: string,
    isLoaning: boolean,
    loanInput: string,
    vaultType?: VaultType,
    referralAccount?: ReferralAccount
  ) {
    this.vaultType = vaultType
    this.referralAccount = referralAccount
    if (!isNaN(parseFloat(depositInput))) {
      this.collateral = new Decimal(parseFloat(depositInput))
      this.collateralValid = true
      if (!isDepositing) {
        this.collateral = new Decimal(this.collateral).neg()
      }
    }

    if (!isNaN(parseFloat(loanInput))) {
      this.debt = new Decimal(parseFloat(loanInput))
      this.debtValid = true
      if (!isLoaning) {
        this.debt = new Decimal(this.debt).neg()
      }
    }

    // if (this.debtValid && this.debt.greaterThan(0) && vaultType) {
    //   let loanFee = new Decimal(this.debt).mul(vaultType.loanInitFee)
    //   if (referralAccount) {
    //     const referralDiscountPercent = referralAccount.referredUserDiscount
    //     const discountInUsh = loanFee.mul(referralDiscountPercent)
    //     loanFee = loanFee.sub(discountInUsh)
    //   }

    //   this.debt = this.debt.add(loanFee)
    // }
  }

  /**
   *
   * @returns The loan fees in USH (excludes any referral discounts)
   */
  getLoanFee(): Decimal {
    if (this.debtValid && this.debt.greaterThan(0) && this.vaultType) {
      let loanFee = new Decimal(this.debt).mul(this.vaultType.loanInitFee)

      return loanFee
    }
    return new Decimal(0)
  }

  /**
   *
   * @returns The referral discount on the loan fees in USH
   */
  getReferralDiscount(): Decimal {
    if (this.getLoanFee().gt(0) && this.referralAccount) {
      const discountInUsh = this.getLoanFee().mul(this.referralAccount.referredUserDiscount)
      return discountInUsh
    }
    return new Decimal(0)
  }
}

export enum VaultStatus {
  Initialized,
  Open,
  Closed,
  Liquidated,
  Distributed,
  Redeemed,
}